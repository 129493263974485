import React, { Suspense } from "react";

import { Route, Routes, Navigate } from "react-router-dom";

// import AllQuotes from "./pages/AllQuotes";
// import QuoteDetail from "./pages/QuoteDetail";
// import Comments from "./components/comments/Comments";
// import NewQuote from "./pages/NewQuote";
import NotFound from "./pages/NotFound";
import Layout from "./components/layout/Layout";
import { Fragment } from "react";

const NewQuote = React.lazy(() => import("./pages/NewQuote"));
const AllQuotes = React.lazy(() => import("./pages/AllQuotes"));
const QuoteDetail = React.lazy(() => import("./pages/QuoteDetail"));
const Comments = React.lazy(() => import("./components/comments/Comments"));

const routes = [
  {
    id: "r1",
    path: "/",
    element: <Navigate replace to="/quotes" />,
  },
  {
    id: "r2",
    path: "/quotes",
    element: <AllQuotes />,
  },
  {
    id: "r3",
    path: "/quotes/:quotesId",
    element: <QuoteDetail />,
  },
  {
    id: "r4",
    path: "/quotes/:quotesId/comments",
    element: (
      <Fragment>
        <QuoteDetail />
        <Comments />
      </Fragment>
    ),
  },
  {
    id: "r5",
    path: "/new-quote",
    element: <NewQuote />,
  },
  {
    id: "r6",
    path: "*",
    element: <NotFound />,
  },
];

function App() {
  return (
    <Layout>
      <Suspense>
        <Routes>
          {routes.map((route) => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
          {/* <Route path='/' exact>
          <Redirect to='/quotes' />
        </Route>
        <Route path='/quotes' exact>
          <AllQuotes />
        </Route>
        <Route path='/quotes/:quoteId'>
          <QuoteDetail />
        </Route>
        <Route path='/new-quote'>
          <NewQuote />
        </Route>
        <Route path='*'>
          <NotFound />
        </Route> */}
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
